import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';

import '../generic-page.scss';
import {
  faFacebook,
  faGoogle,
  faApple,
  faReddit,
  faDiscord,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import {
  faGlobe,
  faArrowUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeAboutPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>About the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/nikke/categories/category_about.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>About the game</h1>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
      <div className="page-content">
        <SectionHeader title="NIKKE" />
        <p>
          GODDESS OF VICTORY: NIKKE is mobile third-person shooter RPG that
          focuses on visuals, a one-handed control scheme, and futuristic story
          that follows humanity after it was forced underground by invaders. The
          only thing standing between the annihilation of all humankind and a
          new beginning is you and your actions in this new adventure that mixes
          shooter-style gameplay and card collecting in a world that is just
          waiting to be explored.
        </p>
        <p>
          Set in a world following the Rapture invasion that set the planet
          ablaze, threatening the life of every human; GODDESS OF VICTORY: NIKKE
          follows humanity and the humanoid girls named Nikke that were built to
          defeat this threat made by Rapture and reclaim the planet. A group of
          these Nikke wake up in the Ark - mankind’s new home - providing a new
          hope for the human race that has long since been extinguished.
        </p>
        <SectionHeader title="Official description" />
        <p>
          GODDESS OF VICTORY: NIKKE is an immersive sci-fi RPG shooter game,
          where you recruit and command various maidens to form a beautiful
          anime girl squad that specializes in wielding guns and other unique
          sci-fi weapons. Command and collect girls that have unique combat
          specialties to create your ultimate team! Experience next-level
          shooting action with simple yet intuitive controls while enjoying
          dynamic battle effects.
        </p>
        <p>Humanity lies in ruins.</p>
        <p>
          The Rapture invasion came without warning. It was both ruthless and
          overwhelming. The reason: unknown. No room for negotiations. In what
          seemed like an instant, the earth was turned into a sea of fire.
          Countless humans were hunted down and slaughtered without mercy. None
          of mankind's modern technology stood a chance against this colossal
          invasion. There was nothing that could be done. The humans were laid
          to waste. Those that managed to survive found one thing that gave them
          the smallest glimmer of hope: the humanoid weapons. However, once
          developed, these new weapons were far from the miracle everyone
          needed. Instead of turning the tide, they only managed to make a minor
          dent. It was a complete and utter defeat. Humans lost their homeland
          to the Rapture and were forced to live deep underground.
        </p>
        <p>
          Decades later, a group of girls awakens in the Ark, mankind's new
          home. They are the result of the collective technological knowledge
          pooled together by all the humans driven underground. The girls board
          an elevator to the surface. It has not been operated for decades.
          Humanity prays. May the girls be their swords. May they become the
          blade that enacts revenge for humanity. Born out of mankind's
          desperation, the girls head to the world above, carrying the hopes and
          dreams of the human race on their shoulders. They are code-named
          Nikke, a name derived from the Greek Goddess of Victory, Nike.
          Mankind's last hope for victory.
        </p>
        <h6>Stand-out Characters with Distinctive Personalities</h6>
        <p>
          Alluring and extraordinary Nikkes. Watch as character illustrations
          jump off the page and straight into battle. Play now!
        </p>
        <h6>Featuring vivid, high-quality illustrations.</h6>
        <p>
          Advanced animation and animated illustration with cutting-edge
          technology, including the latest physics engine and plot-based auto
          motion-sensing controls. Witness characters and images, unlike
          anything you’ve seen before.
        </p>
        <h6>Experience The First-hand Unique Tactics</h6>
        <p>
          Use a variety of character weapons and Burst Skills to take down the
          overwhelming invaders. Feel the thrill of a brand-new innovative
          battle system.
        </p>
        <h6>A Sweeping In-Game World and Plot</h6>
        <p>
          Play your way through a post-apocalyptic tale with a story that offers
          both thrills and chills.
        </p>
        <SectionHeader title="Promotional images" />
        <div className="multiple-images">
          <StaticImage
            src="../../images/nikke/nikke_promo_1.jpg"
            alt="Promotional image"
          />
          <StaticImage
            src="../../images/nikke/nikke_promo_2.jpg"
            alt="Promotional image"
          />
          <StaticImage
            src="../../images/nikke/nikke_promo_3.jpg"
            alt="Promotional image"
          />
          <StaticImage
            src="../../images/nikke/nikke_promo_4.jpg"
            alt="Promotional image"
          />
        </div>
        <SectionHeader title="Official links" />
        <Tabs
          defaultActiveKey="Global"
          transition={false}
          id="current-events-tabs"
          className="current-event-tabs"
        >
          <Tab eventKey="Global" title="Global">
            <Row>
              <Col xs="12" xl="8" xxl="6">
                <div className="list-box">
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGlobe} /> Website
                    </div>
                    <div className="list-value">
                      <a
                        href="https://nikke-en.com/index.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faFacebook} /> Facebook
                    </div>
                    <div className="list-value">
                      <a
                        href="https://www.facebook.com/NIKKE.Global/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faTwitter} /> Twitter
                    </div>
                    <div className="list-value">
                      <a
                        href="https://twitter.com/NIKKE_en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGoogle} />
                      Google Play
                    </div>
                    <div className="list-value">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.proximabeta.nikke&hl=en_US&gl=US"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faApple} /> Apple Store
                    </div>
                    <div className="list-value">
                      <a
                        href="https://apps.apple.com/us/app/goddess-of-victory-nikke/id1585915174"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faReddit} /> Reddit
                    </div>
                    <div className="list-value">
                      <a
                        href="https://www.reddit.com/r/NikkeMobile/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faDiscord} /> Discord
                      (official)
                    </div>
                    <div className="list-value">
                      <a
                        href="https://discord.com/invite/nikke-en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row ">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faDiscord} /> Discord
                      (unofficial)
                    </div>
                    <div className="list-value">
                      <a
                        href="https://discord.gg/Gpt2KykNpw"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
    </DashboardLayout>
  );
};

export default NikkeAboutPage;

export const Head: React.FC = () => (
  <Seo
    title="About the game | NIKKE | Prydwen Institute"
    description="Learn more about Goddess of Victory: NIKKE gacha game."
    game="nikke"
  />
);
